<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/JGGK' }">机构概况</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <h1 align="center" style="text-align:center;">
        国家级放射影像专业质控中心专家委员会工作章程
      </h1>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        国家级放射影像专业质控中心专家委员会工作章程
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        一、委员会的组成
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;国家级放射影像专业质控中心专家委员会由放射影像专业医疗质控领域的专家、学者和相关人员组成。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;专家委员会成员的资格包括：具有丰富的医疗质量控制经验，在相关领域具有较高的学术水平和威望，良好的职业道德和敬业精神。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        二、职责与任务
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;制定放射影像专业质控标准和规范，推动射影像专业质控管理的规范化、标准化和科学化。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;对放射影像专业医疗质量进行监督、检查和评估，及时发现和解决相关医疗质量问题。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;组织开展射影像专业医疗质量培训和教育活动，提高射影像专业医务人员的医疗质量意识和技能水平。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;定期向国家卫健委有关部门报告工作，提出射影像专业改进医疗质量管理的意见和建议。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        三、工作程序
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;会议组织
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        专家委员会每季度召开一次全体会议，可以以线上或线下形式举行，对委员会的工作进行总结和部署。根据需要，可以召开临时会议，讨论重要事项。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;议事规则
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        会议由国家级射影像专业质控中心主任委员/副主任委员主持，专家委员会全体委员参加。会议讨论的议题包括但不限于制定射影像专业医疗质量控制标准和规范，对射影像专业医疗质量进行监督、检查和评估，组织开展射影像专业医疗质量培训和教育活动等。会议应充分发扬民主，充分听取不同观点，寻求共同点，形成共识。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        四、决策与执行
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;投票与决策
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        对于重要事项，专家委员会应当采用投票方式进行决策。参与投票的委员数量应不低于专家委员会全体<span>委员数量的</span><span>2</span>/3。投票结果应当以多数票通过为原则。在投票过程中，应当遵循公平、公正、公开的原则，不得出现任何形式的舞弊行为。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;执行与监督
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        专家委员会通过的决议应当得到认真执行。对于决议的执行情况，专家委员会应当进行监督和检查。对于违反决议的行为，应当及时予以纠正和处理。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        五、培训与发展
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;培训计划
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        专家委员会应当制定放射影像专业质控培训计划，包括培训目标、培训内容、培训时间和培训方式等。培训计划应当注重实效性和针对性，确保培训效果和质量。同时，委员会还应当加强对医务人员的日常培训和教育，提高医务人员的医疗质量意识和技能水平。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        以上是国家级射影像专业质控中心专家委员会的工作章程，旨在明确专家委员会的组成、职责与任务、工作程序、决策与执行以及培训与发展等方面的内容。希望全体委员认真遵守章程规定，共同推动放射影像专业医疗质量管理工作的规范化、标准化和科学化发展。
      </p>
      <p>
        <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
name:"WYYGZZC"
}
</script>

<style scoped>
.wrap {
  width: 70%;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 22px;
  margin-bottom: 40px;
}
</style>